@import '~taltech-styleguide/scss/variables.scss';

body {
  min-width: 320px;
}

.page--fullscreen {
  z-index: $zindex-modal;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--bs-body-bg);
}

.status-tag.status-tag--light-success::before {
  color: rgba(var(--bs-success-rgb), 0.5);
}

// scroll margin that takes into account the header height
.tt-scroll-margin {
  scroll-margin-top: 8rem;
}
